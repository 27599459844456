:root {
  --font-increase-px: 6px;
  --font-increase-em: 0.375em;
  --font-increase-rem: 0.375rem;
}

body {
  margin: 0;
  font-family: Lato, Sans-Serif;
  font-size: calc(1rem + var(--font-increase-rem));
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-color: #161618;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 87px;
  position: relative;
  min-height: 100vh;
}

body:has(.dashboard-redesign) {
  background-color: #1c1d21;
}

a {
  color: #5fc4b8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #b8fff7;
  text-decoration: underline;
}

a:active {
  color: #b8fff7;
  opacity: 0.3;
}

a,
button,
.pointer {
  cursor: pointer !important;
}

#feedback_icon {
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 12px;
  width: 42px;
  height: 42px;
  z-index: 9999;
  animation: load_up 1s forwards;
  -webkit-animation: load_up 1s forwards;
}

.removeModal {
  z-index: 99999;
}
.removeModal .modal-content {
  background: #25272b;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 50%);
  border-radius: 5px;
}
.removeModal .modal-dialog {
  max-width: 400px;
}

@keyframes load_up {
  from {
    bottom: -100px;
  }
  to {
    bottom: 12px;
  }
}

@-webkit-keyframes load_up {
  from {
    bottom: -100px;
  }
  to {
    bottom: 12px;
  }
}
/**Update the primary and secondary Buttons**/
.btn-large {
  height: 48px;
  padding: 16px 24px;
}

.btn-small {
  padding: 8px;
  height: 32px;
}

.btn-primary,
.btn-secondary {
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  border-color: #5fc4b8;
  line-height: 16px;
  white-space: nowrap;
  letter-spacing: 1px;
}
.btn-primary {
  color: #161618;
  background-color: #5fc4b8;
  border-radius: 5px;
}
.btn-primary:hover {
  color: #161618;
  background: #b8fff7 !important;
  border-color: #b8fff7 !important;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .dropdown-toggle.btn-primary {
  color: #161618;
  background: #b8fff7;
  border-color: #b8fff7;
  opacity: 0.3;
}
.disabled.btn-primary,
.btn-primary:disabled {
  color: #161618;
  background-color: #5fc4b8;
  border-color: #5fc4b8;
  opacity: 0.3;
}

.btn-secondary {
  color: #5fc4b8;
  background-color: rgba(255, 255, 255, 0);
  border: #5fc4b8 1px solid;
}

.btn-secondary:hover {
  color: #b8fff7;
  background-color: rgba(255, 255, 255, 0);
  border-color: #b8fff7;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  opacity: 0.3;
  background-color: rgba(255, 255, 255, 0);
  color: #5fc4b8;
  background-color: #5fc4b8;
  border-color: #5fc4b8;
}

.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  opacity: 0.3;
  background-color: rgba(255, 255, 255, 0);
  color: #b8fff7;
  border-color: #b8fff7;
}
.btn-primary:focus,
.btn-primary:focus-visible,
.btn-secondary:focus-visible,
.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
}
/**Update the primary and secondary Buttons END**/
