.registration-form__header {
  padding: 2rem 0 3rem;
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0.3rem;
  text-align: center;
  text-transform: uppercase;
}

.typeform-error {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#embedded-typeform {
  height: 770px;
  margin: 0 15rem 2rem;
}

@media only screen and (max-width: 920px) {
  #embedded-typeform {
    margin: 0 10rem;
  }
}

@media only screen and (max-width: 760px) {
  #embedded-typeform {
    margin: 0 5rem;
  }
}

@media only screen and (max-width: 480px) {
  #embedded-typeform {
    margin: 0 1rem;
  }
}

@media screen and (min-width: 1199px) {
  .registration-form__header {
    padding: 3rem 0;
  }
}
