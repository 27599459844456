.MathJax_SVG,
.MathJax_SVG svg {
  /* Make sure the long equations will not overrun the learning pane */
  max-width: 100% !important;
}

/* Create an overlay so the user won't able to collapse expressions.
MathJax doesn't allow to use explorer feature without collapse feature.
Detail: https://github.com/mathjax/MathJax-a11y/issues/175 */
.MathJax_SVG {
  position: relative;
}
.MathJax_SVG::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

div.single_equation {
  display: inline-block;
  font-size: 18px !important;
}

#react-mathjax-preview span {
  display: unset;
}
